<template>
  <q-tab-panels v-model="imageSlide" animated swipeable infinite>
    <q-tab-panel
      v-for="(image, i) in images"
      :key="image + '_' + i + '_listingImage'"
      :name="i"
      class="q-pa-none"
    >
      <q-img
        v-if="imageSlide === i"
        :src="images[i]"
        spinner-color="white"
        transition="fade"
        :ratio="ratio"
        fit="contain"
        style="width: 100%; height: auto;"
        loading="lazy"
        class="bg-background-light"
      >
        <template v-slot:error>
          <q-img
            src="https://d2z3ffoe99vnx9.cloudfront.net/vehicle_images/no_image_available.jpeg"
            spinner-color="white"
            transition="fade"
            :ratio="ratio"
            fit="contain"
            style="width: 100%; height: auto; background: #f2f2f2;"
            loading="lazy"
          />
        </template>

        <div
          class="absolute-bottom-right text-subtitle2"
          style="border-radius: 4px;padding: 4px 8px;bottom: 16px;right: 16px;font-size: 0.9em;"
        >
          {{ imageSlide + 1 }} of {{ images.length }}
        </div>

        <div
          v-if="images.length > 1"
          @click.stop="
            imageSlide = imageSlide === images.length - 1 ? 0 : imageSlide + 1
          "
          class="q-carousel__control q-carousel__arrow q-carousel__next-arrow q-carousel__next-arrow--horizontal absolute flex flex-center"
          style="background: none"
        >
          <q-btn flat round color="white">
            <q-icon
              name="sym_r_chevron_right"
              :style="[chevronSize === '' ? '' : { 'font-size': chevronSize }]"
            />
          </q-btn>
        </div>

        <div
          v-if="images.length > 1"
          @click.stop="
            imageSlide = imageSlide === 0 ? images.length - 1 : imageSlide - 1
          "
          class="q-carousel__control q-carousel__arrow q-carousel__prev-arrow q-carousel__prev-arrow--horizontal absolute flex flex-center"
          style="background: none"
        >
          <q-btn flat round color="white">
            <q-icon
              name="sym_r_chevron_left"
              :style="[chevronSize === '' ? '' : { 'font-size': chevronSize }]"
            />
          </q-btn>
        </div>
      </q-img>
    </q-tab-panel>
  </q-tab-panels>
</template>

<script>
export default {
  // TODO: Add 'preload' functionality.
  name: "ImageCarousel",
  props: {
    images: {
      type: Array,
      required: true
    },
    ratio: {
      default: 4 / 3
    },
    chevronSize: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      imageSlide: 0
    };
  }
};
</script>

<style lang="scss" scoped></style>
