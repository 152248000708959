<template>
  <div class="lead-form-dialog-container">
    <q-dialog :ref="modalRef" v-cloak full-width position="bottom">
      <DialogHeader header="Check Availability" />

      <q-card>
        <q-card-section class="q-pb-none">
          <div class="row items-center justify-start no-wrap">
            <q-img
              :src="vehicle.images[0]"
              spinner-color="white"
              transition="fade"
              :ratio="4 / 3"
              fit="contain"
              style="width: 150px; height: auto; background: #000;border-radius: 4px"
              loading="lazy"
            >
              <template v-slot:error>
                <q-img
                  src="https://d2z3ffoe99vnx9.cloudfront.net/vehicle_images/no_image_available.jpeg"
                  spinner-color="white"
                  transition="fade"
                  :ratio="ratio"
                  fit="contain"
                  style="width: 100%; height: auto; background: #f2f2f2;"
                  loading="lazy"
                />
              </template>
            </q-img>
            <div
              style="width: calc(100% - 150px)"
              class="q-ml-md column items-start justify-between"
            >
              <div class="q-mb-xs full-width">
                <h6
                  v-html="vehicle.title"
                  class="--no-style ellipsis q-ma-none"
                />
                <p
                  class="q-ma-none text-text-light ellipsis --text-xs"
                  style="margin-top: -2px"
                >
                  {{ vehicle.trim }}
                </p>
              </div>

              <h4 class="text-weight-bold ellipsis q-mb-sm">
                {{ vehicle.price_formatted }}
              </h4>

              <p
                class="--no-style row no-wrap items-center ellipsis --text-xs"
                style="font-weight: 700"
              >
                <q-icon
                  name="sym_r_speed"
                  class="q-mr-xs"
                  style="font-size: 13px"
                />
                <span
                  v-html="vehicle.mileage_formatted + ' miles'"
                  class="ellipsis"
                />
              </p>

              <p
                class="--no-style text-text-light row no-wrap items-center ellipsis --text-xs"
                style="font-weight: 500"
              >
                <q-icon
                  name="sym_r_place"
                  class="q-mr-xs"
                  style="font-size: 11px"
                />
                <span
                  v-html="vehicle.dealer.city + ', ' + vehicle.dealer.state"
                  class="ellipsis"
                />
              </p>
            </div>
          </div>
        </q-card-section>
      </q-card>

      <q-card flat square style="width: 100%">
        <q-card-section
          :class="{
            'q-py-xl': leadSubmitted
          }"
        >
          <jfpc-vehicle-lead-form
            publisher-account-id="10001"
            component-placement="srp"
            component-theme="carscience"
            dark-mode
            :channel-one="
              urlParams().get('utm_campaign')
                ? urlParams().get('utm_campaign')
                : ''
            "
            :product-id="vehicle.product_id"
            hide-disclaimer
            @submitted="leadSubmitted = true"
          />
          <div v-show="!leadSubmitted" class="disclaimer q-mt-sm">
            By clicking "Check Availability", you authorize carscience.com and
            its sellers/partners to contact you via emails/texts/calls.
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogHeader from "@/components/UI/DialogHeader.vue";

export default {
  name: "LeadFormDialog",
  components: { DialogHeader },
  props: {
    vehicle: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalRef: "leadFormDialog",
      lead: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        postal_code: this.$store.state.search.location.postalCode
          ? this.$store.state.search.location.postalCode
          : ""
      },
      disableSubmit: false,
      leadSubmitted: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    show() {
      this.$refs[this.modalRef].show();
    },
    hide() {
      this.$refs[this.modalRef].hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.lead-form-dialog-container {
  background: color(background);
}
</style>
